import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

export function Slider(params) {
    const [first, setfirst] = useState(1);
    let slidetimer;
    useEffect(() => {
        slidetimer = setTimeout(() => {
            if (document.querySelectorAll(".slider-item_img").length < first + 1) {
                setfirst(1);
            } else {
                setfirst(first + 1);
            }
        }, 8000);
        timer();
    }, [first]);
    function timer(params) {
        if (params) {
            setfirst(params);
            clearInterval(slidetimer);
        }
    }
    // 8mart
    return (
        <section className="slider">
            <div className="container">
                <div className="slider-left__text-block">
                    <div className="slider-left__text_items">
                        {/* <div className={first === 1 ? "slider-left__text_item active" : "slider-left__text_item"}>
                            <h2 className="slider_title">
                                С МЕЖДУНАРОДНЫМ
                                <br />
                                ЖЕНСКИМ ДНЁМ!
                            </h2>
                        </div> */}
                        <div className={first === 1 ? "slider-left__text_item active" : "slider-left__text_item"}>
                            <h2 className="slider_title">
                                Лучшее
                                <br /> предложение
                            </h2>
                            <div className="slider-left__text_mini_box">
                                <p className="slider-left__text_mini">Топливозаправщик</p>
                                <p className="slider-left__text_mini">362233 (УАЗ)</p>
                            </div>
                            <p className="slider-left__text_opis">ВЫСОКАЯ ПРОХОДИМОСТЬ</p>
                        </div>
                        <div className={first === 2 ? "slider-left__text_item active" : "slider-left__text_item"}>
                            <h2 className="slider_title">
                                надежный и
                                <br /> незаменимый
                            </h2>
                            <div className="slider-left__text_mini_box">
                                <p className="slider-left__text_mini">Пожарный автомобиль</p>
                                <p className="slider-left__text_mini"> 362223 (УАЗ)</p>
                            </div>
                            <p className="slider-left__text_opis">ВЫСОКАЯ ПРОХОДИМОСТЬ</p>
                        </div>
                        <div className={first === 3 ? "slider-left__text_item active" : "slider-left__text_item"}>
                            <h2 className="slider_title">
                                ПОДДЕРЖИМ наших <br /> бойцов вместе!
                            </h2>
                            <div className="slider-left__text_mini_box">
                                <p className="slider-left__text_mini">помощь мобилизованным</p>
                            </div>
                            <NavLink to={`/news/${9}`} className="slider-left__text_opis">
                                <img src={process.env.PUBLIC_URL + "/img/slider/slider_3.2.png"} alt="" /> ПОДРОБНЕЕ
                            </NavLink>
                        </div>
                    </div>
                </div>
                <div className="slider-item">
                    {/* <div className={first === 1 ? "slider-item_img active" : "slider-item_img"}>
                        <img className="slider-item_img_big" src={process.env.PUBLIC_URL + "/img/slider/080325.png"} alt="8 марта" />
                    </div> */}
                    <div className={first === 1 ? "slider-item_img active" : "slider-item_img"}>
                        <img
                            className="slider-item_img_big"
                            src={process.env.PUBLIC_URL + "/img/slider/slider_1.jpg"}
                            alt="Топливозаправщик 362233 (УАЗ)"
                        />
                        <img
                            className="slider-item_img_min"
                            src={process.env.PUBLIC_URL + "/img/slider/slider_1.1.png"}
                            alt="Топливозаправщик 362233 (УАЗ)"
                        />
                    </div>
                    <div className={first === 2 ? "slider-item_img active" : "slider-item_img"}>
                        <img
                            className="slider-item_img_big"
                            src={process.env.PUBLIC_URL + "/img/slider/slider_2.jpg"}
                            alt="Топливозаправщик 362233 (УАЗ)"
                        />
                        <img
                            className="slider-item_img_min bi"
                            src={process.env.PUBLIC_URL + "/img/slider/slider_2.1.png"}
                            alt="Топливозаправщик 362233 (УАЗ)"
                        />
                    </div>
                    <div className={first === 3 ? "slider-item_img active" : "slider-item_img"}>
                        <img className="slider-item_img_big" src={process.env.PUBLIC_URL + "/img/slider/slider_3.jpg"} alt="За своих!" />
                        <img className="slider-item_img_min bi mobil" src={process.env.PUBLIC_URL + "/img/slider/slider_3.1.png"} alt="За своих!" />
                    </div>
                </div>
                <div className="slider-chek">
                    {/* <span onClick={() => timer(1)} className={first === 1 ? "slider-chek_number active" : "slider-chek_number"}>
                        1
                    </span>
                    <span className={first === 1 ? "slider-chek_line active" : "slider-chek_line"}></span> */}
                    <span onClick={() => timer(1)} className={first === 1 ? "slider-chek_number active" : "slider-chek_number"}>
                        1
                    </span>
                    <span className={first === 1 ? "slider-chek_line active" : "slider-chek_line"}></span>
                    <span onClick={() => timer(2)} className={first === 2 ? "slider-chek_number active" : "slider-chek_number"}>
                        2
                    </span>
                    <span className={first === 2 ? "slider-chek_line active" : "slider-chek_line"}></span>
                    <span onClick={() => timer(3)} className={first === 3 ? "slider-chek_number active" : "slider-chek_number"}>
                        3
                    </span>
                    <span className={first === 3 ? "slider-chek_line active" : "slider-chek_line"}></span>
                </div>
            </div>
        </section>
    );
}
